<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-col cols="12" md="12">
      <base-material-card color="#cf9602" class="px-5 py-3">
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">Addresses</div>
        </template>
        <v-card-text>
          <v-data-table :headers="columns" :items="customerAddressesList" />
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "CustomerAddresses",
  props: ["addresses"],
  data() {
    return { customerAddressesList: this.addresses };
  },
  computed: {
    columns() {
      return [
        {
          sortable: false,
          text: "Delivery type",
          value: "delivery_type",
          width: "150px",
        },
        { sortable: false, text: "Floor", value: "email", width: "200px" },
        { sortable: false, text: "Block", value: "email", width: "200px" },
        { sortable: false, text: "Street", value: "street", width: "150px" },
        {
          sortable: false,
          text: "postcode",
          value: "postcode",
          width: "150px",
        },
        { sortable: false, text: "Address", value: "address", width: "170px" },
      ];
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style>
</style>
